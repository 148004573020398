.section-cont{
    /* margin: 1.5rem 0; */
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    overflow: visible;
    position: relative;
}
.section-cont .float-info-modal{
    position: absolute;
    top: 9rem;
    margin-left: 0.6rem;
    background-color: white;
    padding: 0.3rem;
    border-radius: 10px;
    width: 19rem;
    height: fit-content;
    box-shadow: 0 0 8px 1px rgba(40,40,40,0.4);
    z-index: 1000;
}
.section-cont .float-info-modal.st-2{
    margin-left: 34%;
}
.section-cont .float-info-modal.st-3{
    margin-left: 68%;
}
.section-cont .float-info-modal .container{
    background-color: rgba(252,233,231,1);
    padding:0 0.8rem;
    border-radius: 10px;
}
.section-cont .float-info-modal .container > nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.section-cont .float-info-modal .container > nav h5{
    text-transform: capitalize;
    color: var(--color-primary-red);
}
.section-cont .heading-mn{
    color: black;
    text-transform: capitalize;
    font-size: 2.7rem;
}
.section-cont .sec-heading{
    color: black;
    text-transform: capitalize;
    font-size: 1.4rem;
}
.value-wraper{
    display: flex;
    flex-direction: column;
    height: 50vh;
    align-items: center;
    justify-content: center;;
}
.value-wraper h3{
    margin: 2rem 0;
    text-transform: capitalize;
}
.value-wraper .hgt-value{
    display: flex;
    margin-bottom: 0.89rem;
}
.value-wraper .hgt-value > p{
    color: var(--color-primary-red);
    font-size: 2rem;
}
.range-selector{
    background-color: rgb(223, 222, 222);
    width: 100%;
    position: relative;
    height: 7px;
    border-radius: 50px;
}
.bmr-tag{
    margin-top: 1rem;
    color: black;
    text-transform: capitalize;
    font-size: 1.6rem;
}
.bmr-tag span{
    font-size: 1.9rem;
    color: var(--color-primary-red);
}
.range-selector__bar{
    position: absolute;
    width: 90%;
    top: 0;
    height: 7px;
    background-color: black;
    border-radius: 50px;
}
.range-selector__handle{
    position: absolute;
    width: 36px;
    aspect-ratio: 1/1;
    top: -16px;
    border-radius: 50%;
    background-color: var(--color-primary-red) ;
    cursor: pointer;
}
.flex-row{
    display: flex;
    gap: 1.4rem;
    /* justify-content: space-evenly; */
    flex-wrap: wrap;    
}
.flex-row.arabic{
    justify-content: right;
}
.new-packages .container .bar-new{
    background-color: var(--color-primary-gray);
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.new-packages .container .bar-new.arabic{
    flex-direction: row-reverse;
}

#bar-icon{
    font-size: 1.5rem;
    cursor: pointer;
}
.new-packages .container .bar-new span{
    color: rgba(20,20,20,0.8);
    margin-bottom: 0.5rem;
    width: 90%;
}
.package-card-new{
    padding: 1.5rem;
    outline: none;
    border: 1px solid rgba(70,70,70,0.2);
    border-radius: 0.5rem;
    width: 31.5%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
    position: relative;
    overflow: visible;
}

.package-card-new:hover{
    cursor: pointer;
    border: 1px solid var(--color-primary-red);
}
.package-card-new .top-head{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0.3rem;
    /* background-color: red; */
    /* justify-content: left; */
}
.span-btn{
    display: flex;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    margin-bottom: 0.8rem;
}
.span-btn:hover{
    color: var(--color-primary-red);
    transition: 0.5s;
}
.package-card-new .top-head.arabic h2{
    display: flex;
    justify-content: right;
}
.info-parent-wrap{
    width: 31%;
    position: relative;
}
.info-parent-wrap .wrap-modal .container{
    background-color: rgba(252,233,231);
    border-radius: 7px;
    padding: 0 0.6rem;
}
.info-parent-wrap .wrap-modal .container >nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.info-parent-wrap .wrap-modal .container >nav h5{
    text-transform: capitalize;
    width: fit-content;
    color: var(--color-primary-red);
}
.package-card-new .top-head .red-info{
    color: red;
    font-size: 1.6rem;
    padding: 0.3rem;
    border-radius: 50%;
    box-shadow: 0 2px 2px 1px rgba(70,70,70,0.4);
}
.package-card-new  .cntrl{
    display: flex;
    align-items: center;
    gap: 0.85rem;
}
.package-card-new  .cntrl #cntrl-icon{
    font-size: 1.8rem;
    border-radius: 50%;
    border: 1px solid gray;
    padding: 0.35rem;
    cursor: pointer;
}
.package-card-new .top-head .simple{
    font-weight: 500;
    font-size: 1.2rem;
}
.package-card-new .top-head h2{
    text-transform: capitalize;
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
    color: rgba(20,20,20,0.8);
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.package-card-new .top-head span{
    color: rgba(30,30,30,0.8);
}
.package-card-new.active{
    border: 1px solid var(--color-primary-red);
    outline: none;
    /* background-color: red; */
}
.package-card-new .content-container{
    display: flex;
    flex-direction: column;
}
.order-detail-container{
    background-color: var(--color-primary-gray);
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}
.order-detail-container .img-cntr{
    display: flex;
    align-items: center;
    justify-content: center;
}
.order-detail-container .img-cntr > div {
    width: 30%;
    margin-bottom: 0.8rem;
}
.order-detail-container .img-cntr > div > img{
    width: 100%;
    border-radius: 100%;
    object-fit: contain;
    resize: none;
}
.order-detail-container .order-dt-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.7rem;
    margin-bottom: 0.8rem;
    border-bottom: 1px solid rgba(70,70,70,0.2);
}
.order-detail-container .order-dt-bar div{
    /* background-color: red; */
    width: 40%;
}
.alert-bar-green{
    padding: 1rem;
    border-radius: 0.5rem;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    gap: 1rem;
    /* justify-content: space-between; */
    background-color: #d1ecb2;
}
.alert-bar-green > span {
    color: #198754;
    width: 90%;
}
.btn{
    font-size: 1.1rem;
    padding: 0.5rem 1rem;
    width: 100%;
    border-radius: 25px;
    cursor: pointer;
}
.btn-main{
    background-color: var(--color-primary-red);
    color: white;
    border: 1px solid var(--color-primary-red);
    font-size: 1.1rem;
    padding: 0.7rem 1rem;
    width: 100%;
    border-radius: 25px;
}
.btn-main:hover{
    cursor: pointer;
    background-color: white;
    color: var(--color-primary-red);
    transition: 0.6s;
}
.btn-main.inactive{
    opacity: 0.8;
}
.btn-main.inactive:hover{
    cursor: not-allowed;
    background-color: var(--color-primary-red);
    color: white;
}
.account-container{
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    gap: 1.3rem;
}
.account-container > nav {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.account-container > .arabic {
    justify-content: right;
}
.account-container h4, h5{
    color: rgba(20,20,20,0.8);
    text-transform: none;
}
.account-container form{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.account-container form .btn-main{
    margin: 1rem 0;
}
.account-container .summery{
    padding: 1.5rem;
    border: 1px solid rgba(70,70,70,0.2);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.account-container .summery .row {
    width: 100%;
}
.account-container .summery .row.arabic {
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
}
.account-container .summery .row div {
    width: 50%;
    padding-left: 1rem;
}
.form-justify{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 73vh;
    padding-top: 2rem;
    box-sizing: border-box;
}
.forget-link{
    text-align: right;
    width: fit-content;
    font-size: 0.9rem;
    cursor: pointer;
    user-select: none;
}
.form-input{
    /* background-color: red; */
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid rgb(235, 235, 235);
    overflow: hidden;
    border-radius: 7px;
    height: fit-content;
    gap: 1rem;
}
.form-input > #input-icon-eye{
    position: absolute;
    padding: 0.7rem;
    width: fit-content;
    right: 0;
    height: 45px;
    color: #363636a8;
    /* border-left: 1px solid var(--color-border-gray); */
}
.form-input > #input-icon-eye:hover{
    cursor: pointer;
    color: rgba(40,40,40,0.9);
}
.form-input > #input-icon{
    position: absolute;
    height: 50px;
    width: fit-content;
    color: #363636a8;
    padding: 0.84rem;
    /* border-right: 1px solid var(--color-border-gray); */
    font-size: 0.8rem;
}
.form-input > input {
    width: 100%;
    padding: 0.8rem;
    padding-left: 3rem;
    border-radius: 7px;
    left: 30px;
}
.custom-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 500;
    height: 100vh;
    background-color: rgba(80,80,80,0.4);
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-modal .modal-container{
    background-color: white;
    border-radius: 10px;
    border: 1px solid rgba(40,40,40,0.6);
    min-width: 25%;
    /* overflow: hidden; */
    max-width: 35%;
}
.custom-modal .modal-container > nav{
    display: flex;
    align-items: center;
    padding:0.5rem 0.8rem;
    /* margin-bottom: 0.4rem; */
    justify-content: space-between;
    border-bottom: 1px solid var(--color-border-gray);
}
.custom-modal.mount-modal .modal-container{
    transform-origin: 0%;
    animation: mountmodal 200ms ease-in-out;
}
.custom-modal .body{
    padding:1.2rem 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}
.custom-modal .modal-container footer{
    display: flex;
    align-items: center;
    border-radius: 0 0 10px 10px;
    justify-content: right;
    border-top: 1px solid var(--color-border-gray);
    padding: 0.8rem;
}
.custom-modal .modal-container footer button{
    width: fit-content !important;
    border-radius: 10px;
}
@keyframes mountmodal {
   0%{
    transform: translateY(-100px);
   } 
   100%{
    transform: translateY(0);
   }
}
/* .container{
    min-width: 1200px;
} */
.payment-card{
    padding: 1.5rem;
    border: 1px solid rgba(70,70,70,0.2);
    border-radius: 0.5rem;
    width: 23%;
    display: flex;
    align-items: center;
    gap: 1.4rem;
    cursor: pointer;
}
.payment-card.arabic{
    flex-direction: row-reverse;
    justify-content: right;
}
.payment-card.active{
    border: 1px solid var(--color-primary-red);
}
.payment-card h5{
    font-weight: 500;
    /* width: 100%; */
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
}
.combo-found-txt{
    color: var(--color-primary-red);
    text-align: center;
}
.coupon-bar{
    /* padding: 1.5rem;
    border: 1px solid rgba(70,70,70,0.2);
    border-radius: 0.5rem; */
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    /* justify-content: space-between; */
    /* margin: 1rem 0; */
}
.coupon-bar #card-icon-2{
    position: absolute;
    left: 1rem;
    /* top: auto 0; */
}
.coupon-bar input {
    padding: 1.5rem;
    padding-left: 3.4rem;
    border: 1px solid rgba(70,70,70,0.2);
    border-radius: 0.5rem;
    width: 100%;
    margin: 1rem 0;
}
#arabic{
    text-align: right !important;
    /* display: flex; */
    justify-content: right;
    flex-direction: row-reverse;
}
#card-icon{
    font-size: 1.7rem;
    color: var(--color-primary-red);
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 38.9%;
}
#card-icon-2{
    font-size: 1.7rem;
    color: var(--color-primary-red);
    cursor: pointer;
}
.toogle-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.toogle-row .toogle-opt{
    padding:0.8rem ;
    border: 2px solid rgba(70,70,70,0.2);
    border-radius: 0.5rem;
    width: 32%;
    display: flex;
    cursor: pointer;
    justify-content: center;
}
.toogle-row .toogle-opt.active{
    border: 2px solid var(--color-primary-red);
}
.body-address-form {
    margin: 1rem 0;
}
.body-address-form form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.accordian{
    height: fit-content;
    padding:0.8rem ;
    border-radius: 0.5rem;
    background-color: var(--color-primary-gray);
}
.accordian nav{
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}
.accordian nav div {
    display: flex;
    gap: 10px;
    align-items: center;
}
.accordian nav div svg{
    font-size: 15px;
}
.accordian .content{
    height: 0;
    opacity: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: all 500ms ease-in-out;
}
.accordian .content.show{
    /* min-height: 100px; */
    height: 400px;
    padding: 1rem 0;
    opacity: 1;
}
.calories-result{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    margin-top: 2rem;
}
.calories-result section{
    border: 1px solid var(--color-primary-red);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 550px;
    align-items: center;
    gap: 1.3rem;
    justify-content: center;
    padding: 3rem 2rem;
    box-sizing: border-box;
}
.calories-result #check-icon{
    color: var(--color-primary-red);
    font-size: 4rem;
    font-weight: bold;
}
.calories-result > section > div p{
    font-weight: bold;
    text-align: center;
    margin: 0;
    color: rgba(30,30,30,0.9);
    padding: 0;
    margin:  0.3rem 0;
}
.calories-result > section .wraper-outline{
    border: 1px solid var(--color-border-gray);
    width: 100%;
    border-radius: 5px;
    padding: 1rem 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.info-modal{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
    background-color: rgba(50,50,50,0.6);
}
.info-modal .info-modal-container{
    background-color: white;
    width: 510px;
    /* display: flex; */
    flex-direction: column;
    height: 94%;
    border-radius: 10px;
    display: none;
    border: 1px solid rgba(64, 64, 64, 0.848);
}
.info-modal .info-modal-container section{
    padding-top: 0.6rem;
}
.info-modal .info-modal-container .info-cont{
    background-color: #f5f5f7;
    padding:1rem;
    border-radius: 10px;
    margin: 0.6rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.info-modal .info-modal-container .info-cont a{
    color: var(--color-primary-red);
    /* text-align: center; */
}
.info-modal .info-modal-container.showModal{
    /* transition: 0.6s; */
    display: flex;
}
.info-modal .info-modal-container.hideModal{
    transition: 4s;
    display: none;
    transition: ease;
    transform: translateY(-5rem) ease;    
}
.info-modal .info-modal-container .modal-nav{
    display: flex;
    align-items: center;
    padding: 1rem 1.2rem;
    justify-content: space-between;
    border-bottom: 1px solid #b9b9b986;
}
.info-modal .info-modal-container .modal-nav h4{
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    color: rgba(30,30,30.7);
}
.info-modal .body-container{
    padding: 0.7rem 1.2rem;
    overflow-y: auto;
    max-height: calc(100% - 1rem);
}
.info-modal .body-container > button{
    margin-top: 0.8rem;
}
#close-icon{
    font-size: 1.5rem;
    opacity: 0.6;
}
#close-icon:hover{
    cursor: pointer;
    opacity: 1;
}
#check-icon{
    color: rgb(31, 211, 31);
    font-size: 1.4rem;
}
.info-modal .body-container .info-modal-row{
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem 0;
    border-bottom: 1px solid #b9b9b986;
}
.info-modal .body-container .info-modal-row > h6{
    color: #000000d3;
    text-transform: capitalize;
    width: 60%;
}
.info-modal .body-container .info-modal-row > p{
    text-align: left;
    width: 30%;
}
.address-user{
    display: flex;
    flex-direction: column;
    background-color: rgba(229, 230, 229, 0.736);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    width: fit-content;
    margin-bottom: 0.7rem;
}
.address-user > span:nth-child(2){
    text-transform: capitalize;
}
.google-map-layout{
    border-radius: 15px;
    border: 1px solid rgba(192, 191, 191, 0.726);
    overflow: hidden;
}
.map-address-footer{
    display: flex;
    padding: 1rem;
    box-sizing: border-box;
    justify-content: space-between;

}
.map-address-footer .add{
    display: flex;
    flex-direction: column;
}
.map-address-footer #edit-icon{
    font-size: 1.5rem;
    cursor: pointer;
}
#nav-icon{
    font-size: 2.6rem;
}
.main-map{
    position: relative;
    z-index: 1;
}
.main-map .selected-info{
    background-color: white !important;
    max-width: fit-content;
    padding: 0.6rem;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.6);
    border-radius: 10px;
    font-size: 0.9rem;
    height: fit-content;
    min-width: 13rem;
    z-index: 1000;
    /* pointer-events: none; */
    display: flex;
}
.main-map .direction{
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(0, 166, 255);
}
.main-map .direction span{
    color: rgb(255, 88, 88);
    font-size: 0.9rem;
}



#Spec_Radio [type="radio"]:checked,
#Spec_Radio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
#Spec_Radio [type="radio"]:checked + label,
#Spec_Radio [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
#Spec_Radio [type="radio"]:checked + label:before,
#Spec_Radio [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
#Spec_Radio [type="radio"]:checked + label:after,
#Spec_Radio [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: var(--color-primary-red);
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
#Spec_Radio [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
#Spec_Radio [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}




.Calori_Loader{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    margin-top: 10%;
}



.Calori_Loader p {
    width: 350px;
    text-align: center;
    color: #363636e1;
}

.Calori_Loader h5 {
    font-size: 16px;
    color: #000;
    margin-bottom: 5px;
}



.Form_input .inputField {
    border: 1px solid #c4c2c2;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content:left;
    gap: 10px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 0.8rem;
    padding: 7px 10px;
}

.Form_input .inputField input {
    width: 100%;
    padding-left: 0;
    border: none;
}

.Form_input .inputField svg{
    color: #363636a8;
    font-size:2rem;
    cursor: pointer;
}