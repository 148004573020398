@media (max-width: 859px) {
       
    .flex-row .payment-card{
        min-width: 45%;
    }
}
@media (max-width: 780px) {
    
    .flex-row .payment-card{
        min-width: 100%;
    }

    .order-detail-container .img-cntr > div {
        width: 60%;
        margin-bottom: 0.8rem;
    }

    .package-card-new{
        height: 100px;
    }

    .package-card-new .top-head .simple {
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.6em;
    }
    
    .account-container > p {
        font-size: 4.2vw !important;
    }
}
@media (max-width: 765px) {
    .container{
        padding: 0 5%;
    }
    .package-card-new, .payment-card{
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        /* padding-right: 15%; */
    }
    .flex-row.account{
        flex-wrap: wrap;
    }
    .flex-row.account .package-card-new{
        min-width: 100%;
    }
    .info-parent-wrap{
        min-width: 100%;
    }
    .section-cont .float-info-modal.st-2{
        margin-left: 0.6rem;
        top: 15rem;
    }
    .section-cont .float-info-modal.st-3{
        margin-left: 0.6rem;
        top: 21.6rem;
    }
}
@media (max-width:579px){
    .info-modal{
        width: fit-content;
    }
	.info-modal .info-modal-container{
        width: 95%;
        height: 97%;
	}
    .info-modal .body-container .info-modal-row > h6{
        /* min-width: 250px;    */
    }
}
@media (max-width:340px){
    .info-modal .body-container .info-modal-row > h6{
        min-width: 175px;   
    }
  
}