body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  --color-primary-red: #e52b14;
  --color-primary-gray: #f2f2f2;
  --color-border-gray: rgba(120,120,120,0.4);
}
a{
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.H_SEC{
  opacity: 1;
  height: 657px;
  background-color: rgb(255, 255, 255);
  /* margin-bottom: 1rem; */
  /* border:1px solid red; */
}
.PackageCards{
  /* border: 1px solid red; */
  width: 100%;
 
}
.FTR{
  /* width: 100%; */
}

.HomeSlider button{
  width: 50px;
  opacity: 1;
  z-index: 15000;
}
.HomeSlider .slick-prev{
  /* right: 1px; */
}
.HomeSlider .slick-next{
  /* right: -5px; */
}

.HomeSlider .slick-prev::before{
  color: var(--color-primary-red);
}
.HomeSlider .slick-next::before{
  color: var(--color-primary-red);
}
.FTR{
  width: 100%;
  overflow: visible;
}
.FTRSlider button{
  width: 50px;
  opacity: 1;
  z-index: 15000;
}

.FTRSlider .slick-prev::before{
  color: var(--color-primary-red);
}
.FTRSlider .slick-next::before{
  color: var(--color-primary-red);
}

.IMPORTANT{
  width: 100% !important;
  /* margin-right: 15px !important; */
}
.IMPORTANT > div {
  margin: 0 5px;
}
.IMPORTANT2{
  width: 100% !important;
  /* margin: 0px 7px; */
}
.WEEK{
  width: 176px;
  margin: 0px 5px;
}
.USER_NAME span{
  font-weight: 600;
  font-size: 1.1rem;
  padding-left: 1rem ;
  color: var(--color-primary-red);
}
.navigation .subscribe-div {
  margin-left: 10px;
}
.USER_PHOTO{
  /* border: 2px solid #C5251C; */
  border-radius: 60%;
  overflow: hidden;
  height: 45px;
  width: 45px;
  cursor: pointer;
}
.USER_PHOTO span{
  color: var(--color-primary-red);
  font-size: 2.8rem;
}
.USER_PHOTO img{
  height: 32px;
  margin-top: 2px;
}
.profilePage{
  display: grid;
  grid-template-columns: 13rem auto;
  gap: 0.5rem;
  /* border: 1px solid red; */
  /* background: rgba(128, 128, 128, 0.075); */
  border-radius: 10px;
  padding: 1rem 0;
}
.ProfileNav{
  /* border-right: 1px solid gray; */
  /* padding: 1rem 0; */
}
.ProfileNav ul{
  /* border: 1px solid black; */
  height: 100%;
}
.ProfileNav ul li .LINK{
  text-decoration: none;
}
.ProfileNav ul li span{
  margin-right: 7px;
  font-size: 1.4rem;
}
.ProfileNav ul li {
  display: flex;
  align-items: center;
  /* border: 1px solid gray; */
  font-size: 0.9rem;
  margin-bottom: 5px;
  font-weight: 600;
  padding: 7px 10px;
  color: rgba(0, 0, 0, 0.774);
  border-radius: 7px;
  background: rgba(128, 128, 128, 0.233);
  cursor: pointer;
  transition: 300ms all ease;
}
.ProfileNav ul li:hover{
  padding-left: 1.5rem;
  background: rgba(0, 0, 0, 0.808);
  color: white;
  font-weight: 500;
  /* border: 1px solid rgba(0, 0, 0, 0.808); */
}
.profileData{
  background: rgba(128, 128, 128, 0.233);
}

.PACK_DATE{
  border: 1px solid rgba(0, 0, 0, 0.795);
  /* background: rgba(128, 128, 128, 0.137); */
  border-radius: 6px;
  padding: 0.2rem 1rem;
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.795);
  font-weight: 600;
}
.DATE_STL{
  width: 99%;
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
  border-radius: 10px;
  background: rgba(128, 128, 128, 0.123);
}
.ISFRYD{
  /* color: #fff; */
  border: 1px solid #C5251C;
}
.ISFRYD:hover{
  color: #000;
}





.LoadingScreen{
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.329);
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingText{
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 1rem 2rem;
  border-radius: 5px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.418);
}
.loadingText span{
  color: var(--color-primary-red);
  font-size: 1.2rem;
  margin-left: 1rem;
  font-weight: bold;
}

.loader {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #C5251C;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.wsmenucontainer {
  /* margin-right: 10rem; */
}
.value-choice-right{
  margin-left: 20px;
}
.package-dtl p:nth-child(2){
  display: none;
}
.FTR_BOX{
  /* border: 1px solid red; */
  padding: 0 5px !important;
}
.FTR_BOX .feature-box > p{
  /* border: 1px solid red; */
  height: 5.5rem;
}
.TIME_LABLE::after{
  left: 45% !important;
}
.LOGOUT{
  position: absolute;
  right: 9rem;
  border: 1px solid #C5251C;
  margin: 0;
  font-weight: 600;
  color: var(--color-primary-red);
  padding: 0 1rem;
  background-color: white;
  z-index: 100;
  font-size: 0.9rem;
  border-radius: 4px;
  cursor: pointer;
  transition: 300ms all ease;
}
.LOGOUT:hover{
  background-color: var(--color-primary-red);
  color: #fff;
}

.MEAL_CARD .image{
  width: 100%;
  height: 24vh;
  /* border: 1px solid red; */
}
.MEAL_CARD .image img{
  height: 100%;
  width: 100%;
  object-fit:cover;
}
.mealInfo{
  height: 50px;
}
.mealInfo h5{
  text-align: center;
  color: black;
  font-size: 1.1rem;
  padding: 0px 0px;
  padding-top: 10px;
}
.WEEK_DAYS a {
  display: inline-block;
  padding: 10px 20px;
  background: #fff;
  color: #000;
  border: 2px solid #000;
  font-size: 18px;
  margin: 0 0.5rem;
}
.Week_active{
  background: #000 !important;
  color: #fff !important;
}

.MENU_BTN{
  position: absolute;
  z-index: 100 !important;
  right: 10px;
  top: 10px;
  padding: 4px 10px;
  font-size: 1rem;
}

.CPN_DIV{
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.CPN_DIV h3{
  text-align: center;
}
.CPN_DIV h4{
  margin: 0.5rem 0;
  font-weight: 500;
  font-size: 1.3rem;
}
.CPN_DIV h4 span{
 color: #000;
 margin-left: 0rem;
 font-weight: 600;
}
.CPN_DIV div{
  /* margin-top:1rem; */
  display: inline-flex;
  align-items: center;
}
.CPN_DIV div input{
  margin-right: 1rem;
  border: 1px solid #000;
  padding:0.4rem 0.5rem;
  border-radius: 7px;
}
.WALLET_DIV {
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.WALLET_DIV span{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  margin: 0.5rem 0;
}
.WALLET_DIV span span{
  font-size: 2.5rem;
  margin-right: 0.5rem;
  font-weight:500;
  position: relative;
}
.WALLET_DIV div{
  /* margin-top:1rem; */
  display: inline-flex;
  align-items: center;
}
.WALLET_DIV div input{
  margin-right: 1rem;
  border: 1px solid #000;
  padding:0.4rem 0.5rem;
  border-radius: 7px;
}

.POP_UP{
  position: absolute;
  padding: 1rem 1rem;
  right: -220px;
  top: 100px;
  z-index: 100;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0px 0px 10px  rgba(0, 0, 0, 0.322);
  width: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.POP_UP h4{
  text-align: center;
  color: #000;
}
.POP_UP div a{
  margin: 1rem;
  margin-bottom: 0.2rem;
}

.week-container .swiper-slide .styled-checkbox + label::before{
  z-index: -100;
}

.TITLE_DISH{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TITLE_DISH h1{
  cursor: pointer;
}
.h1Color {
  color: #000;
}
.Reversing{
  flex-direction: row-reverse;
}
.Reversing .BannerText{
  text-align: right;
}
.FlipBanner{
  transform: scaleX(1);
  background-image: url('/public/images/bannerFlip.jpg') !important; 
}

.dishImage{
  height: auto;
  width: auto;
}
.dishImage img {
  object-fit: contain;
  width: 100%;
}

.specialArabic{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 5.5rem;
}
.specialArabic p {
  padding-top: 0px;
  margin: 0;
}